import { Tab as HeadlessTab } from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Tab.module.css";

type TabGroupProps = PropsWithChildren<{
  defaultIndex?: number;
  selectedIndex?: number;
  onChange?: (index: number) => void;
}>;

const TabGroup = (props: TabGroupProps) => {
  return <HeadlessTab.Group {...props} />;
};

type TabListProps = PropsWithChildren<{ className?: string }>;

const TabList = ({ children, className }: TabListProps) => {
  if (!children) return null;

  return (
    <nav className={classNames(styles.nav, className)}>
      <HeadlessTab.List className={styles.list}>{children}</HeadlessTab.List>
    </nav>
  );
};

type TabProps = PropsWithChildren<{
  isActive?: boolean;
  hasError?: boolean;
  title?: string;
  className?: string;
  disabled: boolean | undefined;
}>;

const Tab = ({
  children,
  title,
  isActive,
  hasError,
  className,
  disabled = false,
}: TabProps) => (
  <HeadlessTab
    disabled={disabled}
    title={title}
    className={({ selected }) => {
      return classNames(
        styles.link,
        {
          [styles.linkActive]: isActive ?? selected,
          [styles.linkDisabled]: disabled,
          [styles.linkError]: hasError,
        },
        className,
      );
    }}
  >
    {children}
  </HeadlessTab>
);

Tab.Group = TabGroup;
Tab.List = TabList;
Tab.Panels = HeadlessTab.Panels;
Tab.Panel = HeadlessTab.Panel;

export default Tab;
