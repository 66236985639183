import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";
import Logo from "../assets/logo.svg";
import Button from "./Button";
import Icon from "./Icon";
import styles from "./PageModal.module.css";
import SvgClose from "./icon/Close.svg?react";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

const PageModal = ({ isOpen, children, onClose }: Props) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className={styles.overlay} onClose={onClose}>
      <Dialog.Panel className={styles.dialog}>
        <Dialog.Title className={styles.header}>
          <img className={styles.img} src={Logo} alt="HGV Booking Logo" />
          <Button layout="text" buttonProps={{ onClick: onClose }}>
            <Icon className={styles.cancelIcon} glyph={SvgClose} />
          </Button>
        </Dialog.Title>
        {children}
      </Dialog.Panel>
    </Dialog>
  </Transition>
);
export default PageModal;
