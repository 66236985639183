import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link, To } from "react-router-dom";
import { Glyph } from "../types";
import styles from "./Controls.module.css";
import Icon from "./Icon";
import SvgDelete from "./icon/Delete.svg?react";
import SvgMore from "./icon/More.svg?react";

type ControlsProps = PropsWithChildren<{
  className?: string;
}>;

const Controls = ({ className, children }: ControlsProps) => {
  return (
    <Menu as="div" className={classNames(styles.menu, className)}>
      <Menu.Button className={styles.btn}>
        <Icon glyph={SvgMore} />
      </Menu.Button>
      <Menu.Items className={styles.items}>{children}</Menu.Items>
    </Menu>
  );
};

type GroupProps = PropsWithChildren;

Controls.Group = ({ children }: GroupProps) => (
  <div className={styles.group}>{children}</div>
);

type ItemType = "danger";

type ItemProps = PropsWithChildren<{
  onClick?: () => void;
  glyph?: Glyph;
  disabled?: boolean;
  type?: ItemType;
  title?: string | undefined;
}>;

Controls.Item = ({
  children,
  disabled = false,
  onClick,
  glyph,
  type,
  title,
}: ItemProps) => (
  <Menu.Item disabled={disabled}>
    {({ active }) => (
      <button
        type="button"
        onClick={onClick}
        title={title}
        className={classNames(styles.item, {
          [styles.active]: active,
          [styles.danger]: type === "danger",
          [styles.disabled]: disabled,
        })}
      >
        {glyph && <Icon glyph={glyph} className={styles.itemIcon} />} {children}
      </button>
    )}
  </Menu.Item>
);

type LinkProps = PropsWithChildren<{
  to: To;
  glyph?: Glyph;
}>;

Controls.Link = ({ to, children, glyph }: LinkProps) => (
  <Menu.Item>
    {({ active }) => (
      <Link
        className={classNames(styles.item, {
          [styles.active]: active,
        })}
        to={to}
      >
        {glyph && <Icon glyph={glyph} className={styles.itemIcon} />} {children}
      </Link>
    )}
  </Menu.Item>
);

Controls.Delete = (props: ItemProps) => (
  <Controls.Item glyph={SvgDelete} type="danger" {...props}>
    Löschen
  </Controls.Item>
);

export default Controls;
