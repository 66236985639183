import { Switch } from "@headlessui/react";
import classNames from "classnames";
import styles from "./ToggleSwitch.module.css";

interface Props {
  labelId: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch = ({
  labelId,
  checked,
  disabled = false,
  onChange = () => {},
}: Props) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={classNames(styles.switch, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
    >
      <span
        id={labelId}
        className={classNames(styles.toggle, {
          [styles.checked]: checked,
        })}
      ></span>
    </Switch>
  );
};

export default ToggleSwitch;
