import { Navigate, Route, Routes } from "react-router";
import ErrorImage from "../../assets/error.svg";
import { useRatePlanIdParam } from "../../hooks/useRouterParam";
import { HBK, useRatePlan } from "../../http/dashboardApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import Textarea from "../../ui/Textarea";
import Photos from "./Photos";
import Settings from "./Settings";

const RatePlan = () => {
  const { data: ratePlan, error } = useRatePlan(useRatePlanIdParam());

  if (error) {
    return (
      <EmptyPage
        title="Nicht gefunden"
        image={ErrorImage}
        backLinkText={i18n.navigation.backToOverview}
      >
        Der gesuchte Ratenplan konnte leider nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!ratePlan) return null;

  return (
    <Page
      title={ratePlan.title.de ?? ""}
      backLinkText={i18n.navigation.backToOverview}
      shortInfo={
        <>
          <ShortInfo title="Code">{ratePlan.code}</ShortInfo>
          <ShortInfo title="PMS-Ratenplan">
            {ratePlan.channel_title.de} - {ratePlan.channel_code}
          </ShortInfo>
        </>
      }
      tabs={[
        { to: "settings", text: "Einstellungen" },
        { to: "photos", text: "Fotos" },
        { to: "description", text: "Beschreibung" },
        { to: "pms-codes", text: "PMS-Codes", disabled: true },
      ]}
    >
      <Routes>
        <Route path="settings" element={<Settings ratePlan={ratePlan} />} />
        <Route path="photos" element={<Photos />} />
        <Route
          path="description"
          element={
            <Panel title="Beschreibung">
              <Form<Pick<HBK.RatePlan, "title" | "description">>
                defaultValues={{
                  title: ratePlan.title,
                  description: ratePlan.description,
                }}
                disabled
              >
                {({ register, formState: { errors } }) => (
                  <>
                    <FormField
                      label="Name"
                      helpText="Geben Sie dem Ratenplan einen aussagekräftigen Namen."
                    >
                      {({ labelId }) => (
                        <FormFieldTranslations
                          languages={["de", "it", "en"]}
                          errors={errors.description}
                          labelId={labelId}
                        >
                          {({ language, isInvalid, labelId }) => (
                            <Input
                              id={labelId}
                              type="text"
                              placeholder="Hotel Post"
                              {...register(`title.${language}`)}
                              isInvalid={isInvalid}
                              disabled
                            />
                          )}
                        </FormFieldTranslations>
                      )}
                    </FormField>
                    <FormField
                      label="Beschreibung"
                      helpText="Beschreiben Sie den Ratenplan in ein paar kurzen Sätzen."
                    >
                      {({ labelId }) => (
                        <FormFieldTranslations
                          languages={["de", "it", "en"]}
                          errors={errors.description}
                          labelId={labelId}
                        >
                          {({ language, isInvalid, labelId }) => (
                            <Textarea
                              id={labelId}
                              rows={6}
                              isInvalid={isInvalid}
                              {...register(`description.${language}`)}
                              disabled
                            />
                          )}
                        </FormFieldTranslations>
                      )}
                    </FormField>
                  </>
                )}
              </Form>
            </Panel>
          }
        />
        {/* <Route path="pms-codes" element={<Codes ratePlan={ratePlan} />} /> */}
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </Page>
  );
};

export default RatePlan;
