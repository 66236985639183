import { Link } from "react-router-dom";
import { UseListFiltersReturn } from "../../hooks/useListFilters";
import { HBK, useSearchProperties } from "../../http/dashboardApi";
import i18n from "../../i18n";
import { Paginated } from "../../types";
import { ConfirmDeletionButton } from "../../ui/Button";
import ModalAssign from "../../ui/ModalAssign";
import Table from "../../ui/Table";

interface Props {
  description: string;
  canWrite: boolean;
  isValidating: boolean;
  data: Paginated<{ properties: HBK.AssignedProperty[] }> | undefined;
  onSubmit: (propertyIds: number[]) => void;
  onDelete: (propertyId: number) => void;
  filters: UseListFiltersReturn;
}

const PropertyAssign = ({
  description,
  canWrite,
  data,
  isValidating,
  filters,
  onSubmit,
  onDelete,
}: Props) => {
  return (
    <Table
      description={
        <>
          {description}
          {canWrite && (
            <ModalAssign
              title="Unterkünfte zuweisen"
              description="Wählen Sie die Unterkünfte aus, die Sie zuweisen möchten."
              fetcher={useSearchProperties}
              onSubmit={(properties) => onSubmit(properties.map((p) => p.id))}
              displayValue={(p) => `${p.name}, ${p.address.subdivision}`}
            />
          )}
        </>
      }
      head={
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Ortschaft</th>
          <th>Typ</th>
          <th>Zuweisung</th>
          <th />
        </tr>
      }
      isValidating={isValidating}
      filters={filters}
      total={data?.total ?? 0}
      body={({ styles }) =>
        data?.properties.map((p) => {
          return (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>
                <Link to={`/properties/${p.id}`}>{p.name}</Link>
              </td>
              <td>{p.address.subdivision}</td>
              <td>{i18n.property.category[p.category]}</td>
              <td>
                {p.sources.map((s) => i18n.property.source[s] ?? s).join(", ")}
              </td>
              <td className={styles.alignRight}>
                <ConfirmDeletionButton
                  disabled={!canWrite}
                  modal={{
                    title: "Unterkunft entfernen",
                    description: (
                      <p>
                        Wollen Sie <strong>{p.name}</strong> wirklich entfernen?
                      </p>
                    ),
                  }}
                  onSubmit={() => onDelete(p.id)}
                />
              </td>
            </tr>
          );
        }) ?? []
      }
    />
  );
};

export default PropertyAssign;
