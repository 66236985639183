import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import { DefaultValues, FieldValues, SubmitHandler } from "react-hook-form";
import Button from "./Button";
import Form, { FormChildrenProps } from "./Form";
import Icon from "./Icon";
import styles from "./Modal.module.css";
import SvgClose from "./icon/Close.svg?react";

type Props<TFieldValues extends FieldValues> = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: SubmitHandler<TFieldValues>;
  description?: ReactNode;
  submitText?: string;
  showAbort?: boolean;
  defaultValues?: DefaultValues<TFieldValues> | undefined;
  isScrollable?: boolean;
  className?: string;
  children:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
};

const Modal = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const {
    title,
    description,
    isOpen,
    submitText = "Speichern",
    showAbort = true,
    children,
    isScrollable = false,
    defaultValues,
    className,
    onClose,
    onSubmit,
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={styles.overlay} onClose={onClose}>
        <Dialog.Panel className={classNames(styles.dialog, className)}>
          <Dialog.Title className={styles.title}>
            <div className={styles.header}>
              <Button layout="text" buttonProps={{ onClick: onClose }}>
                <Icon className={styles.cancelIcon} glyph={SvgClose} />
              </Button>
            </div>
            {title}
          </Dialog.Title>
          <Dialog.Description>{description}</Dialog.Description>
          <Form<TFieldValues>
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            secondaryButton={
              showAbort ? (
                <Button
                  layout="hollow"
                  buttonProps={{
                    onClick: onClose,
                  }}
                >
                  Abbrechen
                </Button>
              ) : undefined
            }
            submitText={submitText}
          >
            {(formProps) => (
              <div
                className={classNames(styles.form, {
                  [styles.isScrollable]: isScrollable,
                })}
              >
                {typeof children === "function"
                  ? children(formProps)
                  : children}
              </div>
            )}
          </Form>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};

export default Modal;
