import { useMemo } from "react";
import PropertySearch from "../domain/property/PropertySearch";
import useListFilters from "../hooks/useListFilters";
import usePreviousProperties from "../hooks/usePreviousProperties";
import { usePropertyIdParam } from "../hooks/useRouterParam";
import { HBK, useProperties } from "../http/dashboardApi";
import Menu from "../ui/Menu";
import PropertyCard from "../ui/PropertyCard";
import styles from "./PropertyMenu.module.css";

const PropertyMenu = () => {
  const prev = usePreviousProperties();
  const activeId = usePropertyIdParam();
  const propertyIds = [activeId, ...prev.ids];
  const { data } = useProperties({
    ...useListFilters({
      initialState: {
        page: 1,
        limit: propertyIds.length,
      },
    }).state,
    "id[]": propertyIds,
  });

  const activeProperty = useMemo(
    () => data?.properties.find((p) => p.id === activeId),
    [activeId, data],
  );

  const lastProperties = useMemo(
    () => data?.properties.filter((p) => p.id !== activeId) ?? [],
    [activeId, data],
  );

  return (
    <Menu
      className={styles.menu}
      button={{
        className: styles.button,
        showUnfoldIcon: true,
      }}
      items={{
        children: <Items properties={lastProperties} />,
      }}
    >
      {activeProperty ? (
        <PropertyCard
          name={activeProperty.name}
          address={activeProperty.address.full_address}
        />
      ) : (
        <>Wählen Sie eine Unterkunft…</>
      )}
    </Menu>
  );
};

interface ItemsProps {
  properties: HBK.Property[];
}

const Items = ({ properties }: ItemsProps) => {
  return (
    <>
      <div className={styles.item}>
        <Menu.Item disabled>
          <div aria-hidden={true} className={styles.itemTitle}>
            Unterkunft suchen
          </div>
        </Menu.Item>
        <Menu.Item disabled>
          <PropertySearch />
        </Menu.Item>
      </div>
      {properties.length > 0 && (
        <div className={styles.item}>
          <Menu.Item disabled>
            <div aria-hidden={true} className={styles.itemTitle}>
              Zuletzt
            </div>
          </Menu.Item>
          {properties.map((p) => (
            <Menu.Link key={p.id} to={`/dashboard/properties/${p.id}`}>
              <PropertyCard name={p.name} address={p.address.full_address} />
            </Menu.Link>
          ))}
        </div>
      )}
      <Menu.Item disabled>
        <Menu.Link to="/dashboard/properties">Zeige alle Unterkünfte</Menu.Link>
      </Menu.Item>
    </>
  );
};

export default PropertyMenu;
