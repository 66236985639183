import { Link, useNavigate } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { deleteWidget } from "../../http/adminApi";
import { HBK, usePropertyWidgets } from "../../http/dashboardApi";
import i18n, { getWidgetLayout } from "../../i18n";
import Page from "../../pages/Page";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import Table from "../../ui/Table";

const Widgets = () => {
  const navigate = useNavigate();
  const filters = useListFilters();

  const isAdmin = usePermissions(
    HBK.Permission.WidgetWrite,
    HBK.Permission.Admin,
  );

  const filter = useListFilters();
  const { data, isLoading, isValidating, mutate } = usePropertyWidgets(
    usePropertyIdParam(),
    filter.state,
  );

  const widgets = data?.widgets ?? [];

  return (
    <Page
      title="Widgets"
      description="Widgets können auf Ihrer eigenen Webseite eingebunden werden und helfen Ihnen dabei, den direkten Vertrieb zu stärken."
      isLoading={isLoading}
      controls={
        isAdmin && (
          <AddButton
            buttonProps={{
              onClick: () => navigate("create"),
              disabled: data?.total === HBK.Widget.types.length,
            }}
          >
            Neues Widget
          </AddButton>
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Widget</th>
            <th>Layout</th>
            <th>Eigene Farben</th>
            <th>Erstellt am</th>
            {isAdmin && <th />}
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          widgets.map((w) => {
            return (
              <tr key={w.id}>
                <td>
                  <Link to={w.id}>{i18n.widget.type[w.type]}</Link>
                </td>
                <td>{getWidgetLayout(w.type, w.settings.layout)}</td>
                <td>{w.default_colors ? "Nein" : "Ja"}</td>
                <td>
                  <DateTimeField dateTime={w.created_at} />
                </td>
                {isAdmin && (
                  <td className={styles.alignRight}>
                    <Confirm
                      title={`${i18n.widget.type[w.type]} löschen`}
                      description={
                        <p>
                          Wollen Sie das {i18n.widget.type[w.type]} wirklich
                          löschen?
                        </p>
                      }
                      onConfirm={() =>
                        deleteWidget(w.id).then(
                          () => (mutate(), filters.setPage(1)),
                        )
                      }
                    >
                      {({ open }) => (
                        <Controls>
                          <Controls.Group>
                            <Controls.Link to={w.id}>
                              Zeige Details
                            </Controls.Link>
                            <Controls.Link to={`${w.id}/edit`}>
                              Bearbeiten
                            </Controls.Link>
                          </Controls.Group>
                          <Controls.Group>
                            <Controls.Delete
                              onClick={open}
                              disabled={!isAdmin}
                            />
                          </Controls.Group>
                        </Controls>
                      )}
                    </Confirm>
                  </td>
                )}
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default Widgets;
