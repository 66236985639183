import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useRoomTypeIdParam } from "../../hooks/useRouterParam";
import { HBK, patchRoomType, useRoomType } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import ShortInfo from "../../ui/ShortInfo";
import Amenities from "./Amenities";
import LivingAreas from "./LivingAreas";
import Photos from "./Photos";
import RoomTypeSettings from "./RoomTypeSettings";
import Rooms from "./Rooms";

interface Props {
  languages: HBK.Language[];
}

const RoomType = ({ languages }: Props) => {
  const { data: roomType, mutate } = useRoomType(useRoomTypeIdParam());
  const dispatch = useContext(NotificationContext);
  if (!roomType) return null;

  const defaultLanguage = languages[0];
  const title = defaultLanguage ? roomType.name[defaultLanguage] : undefined;

  const updateRoomType = (body: HBK.RoomTypeBody) => {
    patchRoomType(roomType.id, body)
      .then((res) => {
        mutate(res, false);
        dispatch(showSuccessNotification());
      })
      .catch((error: Error) => dispatch(showErrorNotification(error)));
  };

  return (
    <Page
      title={title ?? ""}
      shortInfo={<ShortInfo title="ID">{roomType.id}</ShortInfo>}
      tabs={[
        { to: "settings", text: "Einstellungen" },
        { to: "photos", text: "Fotos" },
        { to: "living-areas", text: "Wohnbereiche" },
        { to: "amenities", text: "Ausstattung" },
        { to: "rooms", text: "Zimmer" },
      ]}
    >
      <Routes>
        <Route
          path="settings"
          element={
            <RoomTypeSettings
              initialSettings={roomType}
              onSubmit={updateRoomType}
              languages={languages}
            />
          }
        />
        <Route path="photos" element={<Photos />} />
        <Route
          path="living-areas"
          element={
            <LivingAreas
              initialSettings={roomType.living_areas}
              onSubmit={(livingAreas) =>
                updateRoomType({ living_areas: livingAreas })
              }
            />
          }
        />
        <Route
          path="amenities"
          element={<Amenities roomTypeId={roomType.id} />}
        />
        <Route path="rooms" element={<Rooms roomTypeId={roomType.id} />} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </Page>
  );
};

export default RoomType;
