import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import { Glyph } from "../../types";
import Card from "../../ui/Card";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import SvgAccountBalance from "../../ui/icon/AccountBalance.svg?react";
import SvgCreditCard from "../../ui/icon/CreditCard.svg?react";

interface Props {
  profile: HBK.PayoutProfile;
  onDelete: () => void;
}

const PayoutProfile = ({ profile, onDelete }: Props) => {
  return (
    <Card
      title={i18n.payoutProfile[profile.id]}
      glyph={sprites[profile.id]}
      readonly={!profile.editable}
      link={profile.id}
      controls={
        <Confirm
          title="Zahlungsprofil löschen"
          description="Wollen Sie dieses Zahlungsprofil wirklich löschen?"
          onConfirm={onDelete}
        >
          {({ open }) => (
            <Controls>
              {profile.editable && (
                <Controls.Group>
                  <Controls.Link to={profile.id}>Zeige Details</Controls.Link>
                </Controls.Group>
              )}
              <Controls.Group>
                <Controls.Delete onClick={open} />
              </Controls.Group>
            </Controls>
          )}
        </Confirm>
      }
    >
      {profile.description}
    </Card>
  );
};

const sprites: {
  [key in HBK.PayoutProfileId]: Glyph;
} = { bank_account: SvgAccountBalance, on_site: SvgCreditCard };

export default PayoutProfile;
