import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useId, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSearchFilter from "../../hooks/useSearchFilter";
import { HBK, useSearchProperties } from "../../http/dashboardApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Menu from "../../ui/Menu";
import PropertyCard from "../../ui/PropertyCard";
import SvgSearch from "../../ui/icon/Search.svg?react";
import styles from "./PropertySearch.module.css";

const PropertySearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const filter = useSearchFilter();
  const navigate = useNavigate();
  const inputId = useId();

  const { data: results, isValidating } = useSearchProperties({
    query: filter.query,
    signal: filter.signal,
  });

  return (
    <HeadlessCombobox
      as="div"
      onChange={(res: HBK.AssignedProperty) =>
        navigate(`/dashboard/properties/${res.id}`)
      }
    >
      {({ open }) => (
        <>
          <div className={styles.searchField} role="search">
            <HeadlessCombobox.Label
              className={styles.leading}
              htmlFor={inputId}
            >
              <LoadingSpinner
                size="small"
                isLoading={isValidating}
                delayed={100}
              >
                <SvgSearch className={styles.searchIcon} />
              </LoadingSpinner>
            </HeadlessCombobox.Label>
            <HeadlessCombobox.Button
              as="div"
              className={styles.button}
              onClick={(e) => {
                if (open) {
                  e.preventDefault();
                }
              }}
            >
              <HeadlessCombobox.Input
                ref={inputRef}
                id={inputId}
                type="search"
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => filter.setSearchTerm(e.currentTarget.value)}
                placeholder="Suche"
                className={styles.searchInput}
                autoComplete="off"
                value={filter.searchTerm}
              />
            </HeadlessCombobox.Button>
          </div>
          <Transition
            as={Fragment}
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => filter.setSearchTerm("")}
          >
            <HeadlessCombobox.Options as="div" static>
              {!results ||
                (results.length === 0 && filter.searchTerm !== "" && (
                  <div className={styles.noResults}>
                    <strong>Keine Suchergebnisse gefunden</strong>
                  </div>
                ))}
              {results && filter.searchTerm !== "" && (
                <ul className={styles.options}>
                  {results.map((res) => (
                    <HeadlessCombobox.Option
                      key={res.id}
                      value={res}
                      className={({ active }) =>
                        classNames(styles.option, {
                          [styles.active]: active,
                        })
                      }
                    >
                      <Menu.Link
                        key={res.id}
                        to={`/dashboard/properties/${res.id}`}
                      >
                        <PropertyCard
                          name={res.name}
                          address={res.address.full_address}
                        />
                      </Menu.Link>
                    </HeadlessCombobox.Option>
                  ))}
                </ul>
              )}
            </HeadlessCombobox.Options>
          </Transition>
        </>
      )}
    </HeadlessCombobox>
  );
};
export default PropertySearch;
