import { Link, useNavigate } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, deleteRoomType, useRoomTypes } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import Table from "../../ui/Table";
import DateTimeField from "../../ui/DateTimeField";

const RoomTypes = () => {
  const filters = useListFilters();
  const propertyId = usePropertyIdParam();
  const { data, isValidating, mutate } = useRoomTypes(
    propertyId,
    filters.state,
  );
  const canWrite = usePermissions(HBK.Permission.RoomTypeWrite);
  const navigate = useNavigate();

  return (
    <Page
      title="Zimmergruppen"
      controls={
        canWrite && (
          <AddButton
            buttonProps={{
              onClick: () => navigate("create"),
            }}
          >
            Neuer Zimmergruppe
          </AddButton>
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Erstellt am</th>
            <th />
          </tr>
        }
        filters={filters}
        isValidating={isValidating}
        total={data?.total ?? 0}
        body={({ styles }) =>
          data?.room_types.map((r) => {
            return (
              <tr key={r.id}>
                <td>{r.code}</td>
                <td>
                  <Link to={r.id}>{r.name.de}</Link>
                </td>
                <td>
                  <DateTimeField dateTime={r.created_at} />
                </td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="Zimmergruppe löschen"
                    description={
                      <p>
                        Wollen Sie <strong>{r.name.de}</strong> wirklich
                        löschen?
                      </p>
                    }
                    onConfirm={() =>
                      deleteRoomType(r.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={`${r.id}/settings`}>
                            Einstellungen
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/photos`}>
                            Fotos
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/living-areas`}>
                            Wohnbereiche
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/amenities`}>
                            Ausstattung
                          </Controls.Link>
                          <Controls.Link to={`${r.id}/rooms`}>
                            Zimmer
                          </Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete
                            onClick={open}
                            disabled={!canWrite}
                          />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default RoomTypes;
