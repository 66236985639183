import { useMemo } from "react";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import { Glyph } from "../../types";
import Card from "../../ui/Card";
import radioStyles from "../../ui/Radio.module.css";
import SvgBed from "../../ui/icon/Bed.svg?react";
import SvgDateRange from "../../ui/icon/DateRange.svg?react";
import SvgEnquiry from "../../ui/icon/Enquiry.svg?react";
import SvgEuro from "../../ui/icon/Euro.svg?react";
import SvgGift from "../../ui/icon/Gift.svg?react";
import SvgQuickEnquiry from "../../ui/icon/QuickEnquiry.svg?react";
import SvgSunny from "../../ui/icon/Sunny.svg?react";
import SvgWeb from "../../ui/icon/Web.svg?react";

interface Props {
  activeTypes: HBK.Widget.Type[] | undefined;
  type: HBK.Widget.Type;
  isPropertyWidget: boolean;
  onChange: (type: HBK.Widget.Type) => void;
}

interface Widget {
  type: HBK.Widget.Type;
  description?: string;
  icon: Glyph;
}

const widgetsInfo: Widget[] = [
  {
    type: "booking",
    description: "Provisionsfreie Direktbuchungen über Ihre Website.",
    icon: SvgDateRange,
  },
  {
    type: "enquiry",
    description: "Von der unverbindlichen Anfrage zum direkten Gästekontakt.",
    icon: SvgEnquiry,
  },
  {
    type: "quick_enquiry",
    description:
      "Von der unverbindlichen Schnellanfrage zum direkten Gästekontakt.",
    icon: SvgQuickEnquiry,
  },
  {
    type: "special",
    description: "Angebote übersichtlich darstellen.",
    icon: SvgGift,
  },
  {
    type: "room",
    description:
      "Übersichtliche Darstellung Ihrer Zimmer auf der eigenen Webseite.",
    icon: SvgBed,
  },
  {
    type: "prices",
    description:
      "Übersichtliche und aktuelle Preisliste ohne Mehraufwand: durch die Synchronisation mit Ihrem Datenpflegesystem.",
    icon: SvgEuro,
  },
  {
    type: "weather",
    description:
      "Halten Sie die Besucher Ihrer Webseite mit dem akutellen Wetterbericht für Südtirol oder eines entsprechenden Bezirks auf dem Laufenden.",
    icon: SvgSunny,
  },
  {
    type: "portal",
    icon: SvgWeb,
  },
];

interface SelectableWidgets extends Widget {
  selectable: boolean;
}

const getActiveWidgets = (
  activeTypes: HBK.Widget.Type[],
): SelectableWidgets[] =>
  widgetsInfo.map((info) => ({
    ...info,
    selectable: activeTypes.some((w) => w === info.type),
  }));

const WidgetCard = ({
  type,
  isPropertyWidget,
  activeTypes,
  onChange,
}: Props) => {
  const activeWidgets = useMemo(
    () => getActiveWidgets(activeTypes ?? []),
    [activeTypes],
  );

  return (
    <>
      {activeWidgets.map((widget) => {
        if (isPropertyWidget && widget.type === "portal") return;

        return (
          <Card
            key={widget.type}
            title={i18n.widget.type[widget.type]}
            glyph={widget.icon}
            disabled={widget.selectable}
            controls={
              <input
                type="radio"
                className={radioStyles.input}
                checked={type === widget.type}
                disabled={widget.selectable}
                value={widget.type}
                onChange={() => onChange(widget.type)}
              />
            }
          >
            {widget.description}
          </Card>
        );
      })}
    </>
  );
};

export default WidgetCard;
