import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import NoPropertyImage from "../../assets/no-property-found.svg";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import usePreviousProperties from "../../hooks/usePreviousProperties";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, patchProperty, useProperty } from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import CalendarPage from "../calendar/CalendarPage";
import Channel from "../channel/Channel";
import Channels from "../channel/Channels";
import CreateChannel from "../channel/CreateChannel";
import PhotoImporter from "../photo/PhotoImporter";
import CreateReservationPolicy from "../policy/CreateReservationPolicy";
import HouseRules from "../policy/HouseRules";
import ReservationPolicies from "../policy/ReservationPolicies";
import ReservationPolicy from "../policy/ReservationPolicy";
import RatePlan from "../ratePlans/RatePlan";
import RatePlans from "../ratePlans/RatePlans";
import CreateRoomType from "../roomType/CreateRoomType";
import RoomType from "../roomType/RoomType";
import RoomTypes from "../roomType/RoomTypes";
import CreateTomasPairing from "../tomas/CreateTomasPairing";
import TomasPairing from "../tomas/TomasPairing";
import CreateWidget from "../widget/CreateWidget";
import Widget from "../widget/Widget";
import Widgets from "../widget/Widgets";
import Address from "./Address";
import PropertyAmenities from "./Amenities";
import BankAccount from "./BankAccount";
import CreatePayoutProfile from "./CreatePayoutProfile";
import Invitations from "./Invitations";
import LTS from "./LTS";
import Languages from "./Languages";
import OnSite from "./OnSite";
import PayoutProfiles from "./PayoutProfiles";
import Photos from "./Photos";
import Profile from "./Profile";
import PropertyPortals from "./PropertyPortals";
import Redirects from "./Redirects";
import Topics from "./Topics";
import TrustYouForm from "./TrustYouForm";
import Users from "./Users";

const Property = () => {
  const propertyId = usePropertyIdParam();

  const dispatch = useContext(NotificationContext);
  const { data: property, mutate, error } = useProperty(propertyId);

  if (error) return <PropertyNotFound />;
  if (!property) return null;

  return (
    <PropertyRoutes
      property={property}
      onUpdate={(body) => {
        patchProperty(property.id, body)
          .then((prop) => {
            mutate(prop, false);
            dispatch(showSuccessNotification());
          })
          .catch((error: Error) => dispatch(showErrorNotification(error)));
      }}
      mutate={(prop) => mutate(prop, false)}
    />
  );
};

const PropertyNotFound = () => (
  <EmptyPage title="Nicht gefunden" image={NoPropertyImage}>
    Die gesuchte Unterkunft konnte leider nicht gefunden werden.
  </EmptyPage>
);

interface PropertyRoutesProps {
  property: HBK.Property;
  onUpdate: (prop: HBK.PropertyBody) => void;
  mutate: (prop: HBK.Property) => void;
}

const PropertyRoutes = ({ property: prop, onUpdate }: PropertyRoutesProps) => {
  const usePrev = usePreviousProperties();
  useEffect(() => {
    usePrev.save(prop.id);
  }, [prop.id]);

  return (
    <Routes>
      <Route
        path="details/*"
        element={
          <Page
            title="Unterkunft"
            tabs={[
              { to: "profile", text: "Stammdaten" },
              { to: "photos", text: "Fotos" },
              { to: "address", text: "Adresse" },
              { to: "amenities", text: "Ausstattung" },
              { to: "topics", text: "Themen" },
              { to: "payout-profiles", text: "Zahlungsprofile" },
              { to: "languages", text: "Sprachen" },
            ]}
          >
            <Routes>
              <Route path="profile" element={<Profile />} />
              <Route path="photos">
                <Route index element={<Photos />} />
                <Route path="import" element={<PhotoImporter />} />
              </Route>
              <Route
                path="address"
                element={
                  <Address
                    address={prop.address}
                    onAddressChange={({ id, latitude, longitude, street }) =>
                      onUpdate({
                        address: { id, latitude, longitude, street },
                      })
                    }
                  />
                }
              />
              <Route path="amenities" element={<PropertyAmenities />} />
              <Route path="topics" element={<Topics />} />
              <Route path="payout-profiles">
                <Route index element={<PayoutProfiles />} />
                <Route path="create" element={<CreatePayoutProfile />} />
                <Route path="bank_account" element={<BankAccount />} />
                <Route path="on_site" element={<OnSite />} />
              </Route>
              <Route
                path="languages"
                element={
                  <Languages
                    initialLanguages={prop.languages}
                    onSubmit={(languages) => {
                      onUpdate({
                        languages,
                      });
                    }}
                  />
                }
              />
              <Route path="*" element={<Navigate to="profile" replace />} />
            </Routes>
          </Page>
        }
      />
      <Route path="room-types">
        <Route index element={<RoomTypes />} />
        <Route
          path="create"
          element={<CreateRoomType languages={prop.languages} />}
        />
        <Route
          path=":roomTypeId/*"
          element={<RoomType languages={prop.languages} />}
        />
      </Route>
      <Route path="calendar">
        <Route index element={<CalendarPage />} />
      </Route>
      <Route
        path="polices-and-rules/*"
        element={
          <Page
            title="Bedingungen und Regeln"
            tabs={[
              { to: "house-rules", text: "Hausregeln" },
              { to: "reservation-policies", text: "Reservierungsbedingungen" },
            ]}
          >
            <Routes>
              <Route
                path="house-rules"
                element={
                  <HouseRules
                    initialRules={{
                      check_in: prop.check_in,
                      check_out: prop.check_out,
                      children_allowed: prop.children_allowed,
                      children_min_age: prop.children_min_age,
                      cutoff_hours: prop.cutoff_hours,
                    }}
                    onSubmit={onUpdate}
                  />
                }
              />
              <Route path="reservation-policies">
                <Route index element={<ReservationPolicies />} />
                <Route path="create" element={<CreateReservationPolicy />} />
                <Route path=":policyId/*" element={<ReservationPolicy />} />
              </Route>
              <Route path="*" element={<Navigate to="house-rules" replace />} />
            </Routes>
          </Page>
        }
      />
      <Route path="widgets">
        <Route index element={<Widgets />} />
        <Route path="create" element={<CreateWidget />} />
        <Route path=":widgetId/*" element={<Widget />} />
      </Route>
      <Route path="channels">
        <Route index element={<Channels property={prop} />} />
        <Route path="tomas/:tomasId/*" element={<TomasPairing />} />
        <Route path=":channelId/*" element={<Channel property={prop} />} />
        <Route path="create">
          <Route index element={<CreateChannel propertyId={prop.id} />} />
          <Route
            path="tomas"
            element={<CreateTomasPairing propertyId={prop.id} />}
          />
        </Route>
      </Route>
      <Route path="rate-plans">
        <Route index element={<RatePlans propertyId={prop.id} />} />
        <Route path=":ratePlanId/*" element={<RatePlan />} />
      </Route>
      <Route
        path="user-management/*"
        element={
          <Page
            title="Benutzerverwaltung"
            tabs={[
              { to: "users", text: "Benutzer" },
              { to: "invitations", text: "Einladungen" },
            ]}
          >
            <Routes>
              <Route path="users" element={<Users propertyId={prop.id} />} />
              <Route
                path="invitations"
                element={<Invitations propertyId={prop.id} />}
              />
              <Route path="*" element={<Navigate to="users" replace />} />
            </Routes>
          </Page>
        }
      />
      {usePermissions(HBK.Permission.Admin) && (
        <Route path="settings">
          <Route index element={<Navigate to="portals" replace />} />
          <Route
            path="portals"
            element={<PropertyPortals propertyId={prop.id} />}
          />
          <Route
            path="metasearch"
            element={<Redirects propertyId={prop.id} />}
          />
          <Route path="lts" element={<LTS propertyId={prop.id} />} />
          <Route
            path="trust-you"
            element={<TrustYouForm propertyId={prop.id} />}
          />
        </Route>
      )}
      <Route path="*" element={<Navigate to="details" replace />} />
    </Routes>
  );
};

export default Property;
