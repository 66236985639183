import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../context/notificationContext";
import { useFormSteps } from "../../../hooks/useFormSteps";
import { Admin, postProperty } from "../../../http/adminApi";
import PageModal from "../../../ui/PageModal";
import Wizard from "../../../ui/Wizard";
import { getClassificationFromString } from "../../../utils";
import styles from "./NewProperty.module.css";
import NewPropertyFormFields from "./NewPropertyFormFields";

const steps = [
  <NewPropertyFormFields.Name key={0} />,
  <NewPropertyFormFields.Category key={1} />,
  <NewPropertyFormFields.Rating key={2} />,
  <NewPropertyFormFields.Contact key={3} />,
  <NewPropertyFormFields.Address key={4} />,
  <NewPropertyFormFields.Map key={5} />,
];

interface Props {
  initialProperty: Admin.CreateProperty | undefined;
}

const NewProperty = ({ initialProperty }: Props) => {
  const navigate = useNavigate();
  const formSteps = useFormSteps();
  const [property, setProperty] = useState<Admin.CreateProperty | undefined>(
    initialProperty ?? undefined,
  );

  const dispatch = useContext(NotificationContext);

  const onSubmit = (prop: Admin.CreateProperty) =>
    postProperty({
      ...prop,
      ...getClassificationFromString(prop.classification),
      phone_numbers: [prop.phoneNumber],
      guest_interaction: "formal",
      listing_status: "unlisted",
      languages: ["de", "it"],
    })
      .then((res) => {
        navigate(`/dashboard/properties/${res.id}/details/profile`);
        dispatch(
          showSuccessNotification("Die Unterkunft wurde erfolgreich angelegt."),
        );
      })
      .catch((error: Error) => dispatch(showErrorNotification(error)));

  return (
    <PageModal onClose={() => navigate("..")} isOpen>
      <div className={styles.wrapper}>
        {steps.map((step, index) => {
          const lastStep = steps.length === index + 1;

          return (
            formSteps.step === index && (
              <Wizard<Admin.CreateProperty>
                key={index}
                defaultValues={property ?? {}}
                progressProps={{
                  value: formSteps.step + 1,
                  max: steps.length,
                }}
                onSubmit={() =>
                  lastStep && property
                    ? (onSubmit(property), navigate(".."))
                    : formSteps.nextFormStep()
                }
                onAbort={() =>
                  formSteps.step !== 0
                    ? formSteps.prevFormStep()
                    : navigate("..")
                }
                onChange={(values) => setProperty({ ...property, ...values })}
                abortText={formSteps.step !== 0 ? "Zurück" : "Abbrechen"}
                submitText={!lastStep ? "Weiter" : "Speichern"}
              >
                <div className={styles.context}>
                  <div className={styles.scrollable}>{step}</div>
                </div>
              </Wizard>
            )
          );
        })}
      </div>
    </PageModal>
  );
};

export default NewProperty;
